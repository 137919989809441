<fdx-style-wrapper>
    <block-ui>
        <div
            class='app'
            [ngClass]="{ 'view-height': viewHeight, 'vertical-nav': mode === modeType.VERTICAL }">

                @switch (mode) {
                    @case (modeType.NORMAL) {
                        <fdx-app-main-menu/>
                    }
                    @case (modeType.VERTICAL) {
                        <fdx-app-vertical-menu/>
                    }
                    @case (modeType.EBAY) {
                        <fdx-app-ebay-menu/>
                    }
                }

            <router-outlet></router-outlet>
        </div>
        <div id="bs5-container"></div>
    </block-ui>
</fdx-style-wrapper>
